<template>
  <div class="huodong">
    <headerNav></headerNav>
    <el-carousel
      :interval="5000"
      arrow="always"
      height="750px"
      class="screen1920"
    >
      <el-carousel-item v-for="item in bannerList" :key="item">
        <img :src="item.img" alt="" />
      </el-carousel-item>
    </el-carousel>
    <el-carousel
      :interval="5000"
      arrow="always"
      height="146px"
      class="screen750"
    >
      <el-carousel-item v-for="item in bannerList" :key="item">
        <img :src="item.img" alt="" />
      </el-carousel-item>
    </el-carousel>
    <div class="main">
      <div class="mainTitle">
        <img
          src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/huodongzhanshiTitle.png"
          alt=""
        />
        <span>活动展示</span>
      </div>
      <div class="allContent">
        <div class="leftTitle">
          <div
            :class="index == active ? 'leftItemActive leftItem' : 'leftItem'"
            v-for="(item, index) in allList"
            :key="index"
            @click="toSwitch(index, item.title, item.id, item.remark1)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="rightContent">
          <viewer :images="list" v-if="remark1 == '毕业典礼'" class="rightAll">
            <img
              v-for="(src, index) in list"
              :src="src"
              :key="index"
              class="biyeImg"
            />
          </viewer>
          <viewer :images="list" v-if="remark1 == '郊游活动'" class="rightAll">
            <img
              v-for="(src, index) in list"
              :src="src"
              :key="index"
              class="jiaoyouImg"
            />
          </viewer>
          <viewer :images="list" v-if="remark1 == '儿童节'" class="rightAll">
            <img
              v-for="(src, index) in list"
              :src="src"
              :key="index"
              class="ertongImg"
            />
          </viewer>
          <viewer :images="list" v-if="remark1 == '戏剧展'" class="rightAll">
            <img
              v-for="(src, index) in list"
              :src="src"
              :key="index"
              class="xijuImg"
            />
          </viewer>
          <viewer :images="list" v-if="remark1 == '艺术展'" class="rightAll">
            <img
              v-for="(src, index) in list"
              :src="src"
              :key="index"
              class="yishuImg"
            />
          </viewer>
          <viewer :images="list" v-if="remark1 == '消防演练'" class="rightAll">
            <img
              v-for="(src, index) in list"
              :src="src"
              :key="index"
              class="xiaofangImg"
            />
          </viewer>
          <viewer :images="list" v-if="remark1 == '打水仗'" class="rightAll">
            <img
              v-for="(src, index) in list"
              :src="src"
              :key="index"
              class="dashuiImg"
            />
          </viewer>
          <viewer
            :images="list"
            v-if="remark1 == '奥尔夫音乐'"
            class="rightAll"
          >
            <img
              v-for="(src, index) in list"
              :src="src"
              :key="index"
              class="aoerImg"
            />
          </viewer>
        </div>
      </div>
    </div>
    <footerBtm></footerBtm>
  </div>
</template>


<script>
import headerNav from "@/components/nav/nav";
import footerBtm from "@/components/footer";

export default {
  name: "huodong",
  components: { headerNav, footerBtm },
  data() {
    return {
      bannerList: [],
      list: [],
      active: 0,
      remark1: "毕业典礼",
      allList: [],
    };
  },
  mounted() {
    this.api.allPage({ name: "活动展示" }).then((res) => {});
    this.api.banner({ remark1: 9 }).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].img = JSON.parse(res.data[i].img);
      }
      this.bannerList = res.data;
    });
    this.api.hdFangfa({}).then((res) => {
      this.allList = res.data;
    });
    // 默认展示毕业典礼
    this.api.hdDetail(1).then((res) => {
      res.data.data.img1 = JSON.parse(res.data.data.img1);
      res.data.data.img2 = JSON.parse(res.data.data.img2);
      res.data.data.img3 = JSON.parse(res.data.data.img3);
      res.data.data.img4 = JSON.parse(res.data.data.img4);
      res.data.data.img5 = JSON.parse(res.data.data.img5);
      res.data.data.img6 = JSON.parse(res.data.data.img6);
      res.data.data.img7 = JSON.parse(res.data.data.img7);
      res.data.data.img8 = JSON.parse(res.data.data.img8);
      res.data.data.img9 = JSON.parse(res.data.data.img9);
      const list = Array.from(res.data.data.img1).concat(
        res.data.data.img2,
        res.data.data.img3,
        res.data.data.img4,
        res.data.data.img5,
        res.data.data.img6,
        res.data.data.img7,
        res.data.data.img8,
        res.data.data.img9
      );
      this.list = list;
      this.remark1 = "毕业典礼";
    });
  },
  methods: {
    toSwitch(index, title, id, remark1) {
      this.active = index;
      this.api.hdDetail(id).then((res) => {
        if (remark1 == "毕业典礼") {
          res.data.data.img1 = JSON.parse(res.data.data.img1);
          res.data.data.img2 = JSON.parse(res.data.data.img2);
          res.data.data.img3 = JSON.parse(res.data.data.img3);
          res.data.data.img4 = JSON.parse(res.data.data.img4);
          res.data.data.img5 = JSON.parse(res.data.data.img5);
          res.data.data.img6 = JSON.parse(res.data.data.img6);
          res.data.data.img7 = JSON.parse(res.data.data.img7);
          res.data.data.img8 = JSON.parse(res.data.data.img8); 
          res.data.data.img9 = JSON.parse(res.data.data.img9);
          const list = Array.from(res.data.data.img1).concat(
            res.data.data.img2,
            res.data.data.img3,
            res.data.data.img4,
            res.data.data.img5,
            res.data.data.img6,
            res.data.data.img7,
            res.data.data.img8,
            res.data.data.img9
          );
          this.list = list;
          this.remark1 = "毕业典礼";
        } else if (remark1 == "郊游活动") {
          res.data.data.img1 = JSON.parse(res.data.data.img1);
          res.data.data.img2 = JSON.parse(res.data.data.img2);
          res.data.data.img3 = JSON.parse(res.data.data.img3);
          res.data.data.img4 = JSON.parse(res.data.data.img4);
          res.data.data.img5 = JSON.parse(res.data.data.img5);
          res.data.data.img6 = JSON.parse(res.data.data.img6);
          res.data.data.img7 = JSON.parse(res.data.data.img7);
          res.data.data.img8 = JSON.parse(res.data.data.img8);
          res.data.data.img9 = JSON.parse(res.data.data.img9);
          res.data.data.img10 = JSON.parse(res.data.data.img10);
          res.data.data.img11 = JSON.parse(res.data.data.img11);
          const list = Array.from(res.data.data.img1).concat(
            res.data.data.img2,
            res.data.data.img3,
            res.data.data.img4,
            res.data.data.img5,
            res.data.data.img6,
            res.data.data.img7,
            res.data.data.img8,
            res.data.data.img9,
            res.data.data.img10,
            res.data.data.img11
          );
          this.list = list;
          this.remark1 = "郊游活动";
        } else if (remark1 == "儿童节") {
          res.data.data.img1 = JSON.parse(res.data.data.img1);
          res.data.data.img2 = JSON.parse(res.data.data.img2);
          res.data.data.img3 = JSON.parse(res.data.data.img3);
          res.data.data.img4 = JSON.parse(res.data.data.img4);
          res.data.data.img5 = JSON.parse(res.data.data.img5);
          const list = Array.from(res.data.data.img1).concat(
            res.data.data.img2,
            res.data.data.img3,
            res.data.data.img4,
            res.data.data.img5
          );
          this.list = list;
          this.remark1 = "儿童节";
        } else if (remark1 == "戏剧展") {
          res.data.data.img1 = JSON.parse(res.data.data.img1);
          res.data.data.img2 = JSON.parse(res.data.data.img2);
          res.data.data.img3 = JSON.parse(res.data.data.img3);
          res.data.data.img4 = JSON.parse(res.data.data.img4);
          res.data.data.img5 = JSON.parse(res.data.data.img5);
          res.data.data.img6 = JSON.parse(res.data.data.img6);
          const list = Array.from(res.data.data.img1).concat(
            res.data.data.img2,
            res.data.data.img3,
            res.data.data.img4,
            res.data.data.img5,
            res.data.data.img6
          );
          this.list = list;
          this.remark1 = "戏剧展";
        } else if (remark1 == "艺术展") {
          res.data.data.img1 = JSON.parse(res.data.data.img1);
          res.data.data.img2 = JSON.parse(res.data.data.img2);
          res.data.data.img3 = JSON.parse(res.data.data.img3);
          res.data.data.img4 = JSON.parse(res.data.data.img4);
          res.data.data.img5 = JSON.parse(res.data.data.img5);
          res.data.data.img6 = JSON.parse(res.data.data.img6);
          res.data.data.img7 = JSON.parse(res.data.data.img7);
          const list = Array.from(res.data.data.img1).concat(
            res.data.data.img2,
            res.data.data.img3,
            res.data.data.img4,
            res.data.data.img5,
            res.data.data.img6,
            res.data.data.img7
          );
          this.list = list;
          this.remark1 = "艺术展";
        } else if (remark1 == "消防演练") {
          res.data.data.img1 = JSON.parse(res.data.data.img1);
          res.data.data.img2 = JSON.parse(res.data.data.img2);
          res.data.data.img3 = JSON.parse(res.data.data.img3);
          res.data.data.img4 = JSON.parse(res.data.data.img4);
          res.data.data.img5 = JSON.parse(res.data.data.img5);
          res.data.data.img6 = JSON.parse(res.data.data.img6);
          res.data.data.img7 = JSON.parse(res.data.data.img7);
          res.data.data.img8 = JSON.parse(res.data.data.img8);
          res.data.data.img9 = JSON.parse(res.data.data.img9);
          res.data.data.img10 = JSON.parse(res.data.data.img10);
          res.data.data.img11 = JSON.parse(res.data.data.img11);
          const list = Array.from(res.data.data.img1).concat(
            res.data.data.img2,
            res.data.data.img3,
            res.data.data.img4,
            res.data.data.img5,
            res.data.data.img6,
            res.data.data.img7,
            res.data.data.img8,
            res.data.data.img9,
            res.data.data.img10,
            res.data.data.img11
          );
          this.list = list;
          this.remark1 = "消防演练";
        } else if (remark1 == "打水仗") {
          res.data.data.img1 = JSON.parse(res.data.data.img1);
          res.data.data.img2 = JSON.parse(res.data.data.img2);
          res.data.data.img3 = JSON.parse(res.data.data.img3);
          res.data.data.img4 = JSON.parse(res.data.data.img4);
          res.data.data.img5 = JSON.parse(res.data.data.img5);
          res.data.data.img6 = JSON.parse(res.data.data.img6);
          res.data.data.img7 = JSON.parse(res.data.data.img7);
          res.data.data.img8 = JSON.parse(res.data.data.img8);
          res.data.data.img9 = JSON.parse(res.data.data.img9);
          res.data.data.img10 = JSON.parse(res.data.data.img10);
          const list = Array.from(res.data.data.img1).concat(
            res.data.data.img2,
            res.data.data.img3,
            res.data.data.img4,
            res.data.data.img5,
            res.data.data.img6,
            res.data.data.img7,
            res.data.data.img8,
            res.data.data.img9,
            res.data.data.img10
          );
          this.list = list;
          this.remark1 = "打水仗";
        } else if (remark1 == "奥尔夫音乐") {
          res.data.data.img1 = JSON.parse(res.data.data.img1);
          res.data.data.img2 = JSON.parse(res.data.data.img2);
          res.data.data.img3 = JSON.parse(res.data.data.img3);
          res.data.data.img4 = JSON.parse(res.data.data.img4);
          res.data.data.img5 = JSON.parse(res.data.data.img5);
          res.data.data.img6 = JSON.parse(res.data.data.img6);
          res.data.data.img7 = JSON.parse(res.data.data.img7);
          res.data.data.img8 = JSON.parse(res.data.data.img8);
          const list = Array.from(res.data.data.img1).concat(
            res.data.data.img2,
            res.data.data.img3,
            res.data.data.img4,
            res.data.data.img5,
            res.data.data.img6,
            res.data.data.img7,
            res.data.data.img8
          );
          this.list = list;
          this.remark1 = "奥尔夫音乐";
        }
      });
    },
  },
};
</script>
<style scoped>
.screen1920 {
  display: block;
}
.screen750 {
  display: none;
}
.main {
  width: 1280px;
  margin: 30px auto;
}
.mainTitle {
  width: 1200px;
  height: 140px;
  margin: 0 auto;
  position: relative;
}
.mainTitle img {
  width: 100%;
  height: 100%;
}
.mainTitle span {
  font-size: 60px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #25ae85;
  position: absolute;
  left: 50%;
  top: 32px;
  transform: translateX(-50%);
}
.allContent {
  width: 100%;
  margin: 90px auto;
  display: flex;
  justify-content: space-between;
}
.leftTitle {
  width: 240px;
}
.leftItem {
  width: 100%;
  height: 88px;
  background: #ffe500;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  line-height: 88px;
  text-align: center;
  margin-bottom: 80px;
  cursor: pointer;
}
.leftItemActive {
  background: #25ae85;
  font-weight: 500;
  color: #ffffff;
}
.rightContent {
  width: 977px;
}

.rightAll {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  cursor: pointer;
}
.biyeImg,
.jiaoyouImg,
.ertongImg,
.xijuImg,
.yishuImg,
.xiaofangImg,
.dashuiImg,
.aoerImg {
  margin-bottom: 20px;
}
.biyeImg:nth-child(1) {
  width: 381px;
  height: 381px;
}
.biyeImg:nth-child(2) {
  width: 572px;
  height: 381px;
}
.biyeImg:nth-child(3) {
  width: 570px;
  height: 256px;
}
.biyeImg:nth-child(4) {
  width: 384px;
  height: 256px;
}
.biyeImg:nth-child(5) {
  width: 479px;
  height: 318px;
}
.biyeImg:nth-child(6) {
  width: 479px;
  height: 318px;
}
.biyeImg:nth-child(7) {
  width: 309px;
  height: 209px;
}
.biyeImg:nth-child(8) {
  width: 314px;
  height: 209px;
}
.biyeImg:nth-child(9) {
  width: 314px;
  height: 209px;
}
.jiaoyouImg:nth-child(1) {
  width: 478px;
  height: 359px;
}
.jiaoyouImg:nth-child(2) {
  width: 478px;
  height: 359px;
}
.jiaoyouImg:nth-child(3) {
  width: 377px;
  height: 436px;
}
.jiaoyouImg:nth-child(4) {
  width: 581px;
  height: 436px;
}
.jiaoyouImg:nth-child(5) {
  width: 977px;
  height: 388px;
}
.jiaoyouImg:nth-child(6) {
  width: 313px;
  height: 235px;
}
.jiaoyouImg:nth-child(7) {
  width: 313px;
  height: 235px;
}
.jiaoyouImg:nth-child(8) {
  width: 313px;
  height: 235px;
}
.jiaoyouImg:nth-child(9) {
  width: 345px;
  height: 259px;
}
.jiaoyouImg:nth-child(10) {
  width: 257px;
  height: 259px;
}
.jiaoyouImg:nth-child(11) {
  width: 345px;
  height: 259px;
}

.ertongImg:nth-child(1) {
  width: 344px;
  height: 458px;
}
.ertongImg:nth-child(2) {
  width: 611px;
  height: 458px;
}
.ertongImg:nth-child(3) {
  width: 478px;
  height: 358px;
}
.ertongImg:nth-child(4) {
  width: 478px;
  height: 358px;
}
.ertongImg:nth-child(5) {
  width: 977px;
  height: 732px;
}
.xijuImg:nth-child(1) {
  width: 665px;
  height: 420px;
}
.xijuImg:nth-child(2) {
  width: 288px;
  height: 420px;
}
.xijuImg:nth-child(3) {
  width: 977px;
  height: 452px;
}
.xijuImg:nth-child(4) {
  width: 460px;
  height: 543px;
}
.xijuImg:nth-child(5) {
  width: 497px;
  height: 543px;
}
.xijuImg:nth-child(6) {
  width: 977px;
  height: 440px;
}

.yishuImg:nth-child(1) {
  width: 613px;
  height: 459px;
}
.yishuImg:nth-child(2) {
  width: 344px;
  height: 459px;
}
.yishuImg:nth-child(3) {
  width: 344px;
  height: 458px;
}
.yishuImg:nth-child(4) {
  width: 611px;
  height: 458px;
}
.yishuImg:nth-child(5) {
  width: 612px;
  height: 460px;
}
.yishuImg:nth-child(6) {
  width: 345px;
  height: 460px;
}
.yishuImg:nth-child(7) {
  width: 977px;
  height: 390px;
}
.xiaofangImg:nth-child(1) {
  width: 368px;
  height: 276px;
}
.xiaofangImg:nth-child(2) {
  width: 368px;
  height: 276px;
}
.xiaofangImg:nth-child(3) {
  width: 208px;
  height: 276px;
}
.xiaofangImg:nth-child(4) {
  width: 299px;
  height: 245px;
}
.xiaofangImg:nth-child(5) {
  width: 311px;
  height: 245px;
}
.xiaofangImg:nth-child(6) {
  width: 327px;
  height: 245px;
}
.xiaofangImg:nth-child(7) {
  width: 479px;
  height: 358px;
}
.xiaofangImg:nth-child(8) {
  width: 479px;
  height: 358px;
}
.xiaofangImg:nth-child(9) {
  width: 295px;
  height: 240px;
}
.xiaofangImg:nth-child(10) {
  width: 321px;
  height: 240px;
}
.xiaofangImg:nth-child(11) {
  width: 321px;
  height: 240px;
}

.dashuiImg:nth-child(1) {
  width: 453px;
  height: 378px;
}
.dashuiImg:nth-child(2) {
  width: 504px;
  height: 378px;
}
.dashuiImg:nth-child(3) {
  width: 282px;
  height: 357px;
}
.dashuiImg:nth-child(4) {
  width: 427px;
  height: 357px;
}
.dashuiImg:nth-child(5) {
  width: 228px;
  height: 357px;
}
.dashuiImg:nth-child(6) {
  width: 359px;
  height: 269px;
}
.dashuiImg:nth-child(7) {
  width: 287px;
  height: 269px;
}
.dashuiImg:nth-child(8) {
  width: 286px;
  height: 269px;
}
.dashuiImg:nth-child(9) {
  width: 479px;
  height: 359px;
}
.dashuiImg:nth-child(10) {
  width: 479px;
  height: 359px;
}
.aoerImg:nth-child(1) {
  width: 977px;
  height: 349px;
}
.aoerImg:nth-child(2) {
  width: 498px;
  height: 280px;
}
.aoerImg:nth-child(3) {
  width: 459px;
  height: 280px;
}
.aoerImg:nth-child(4) {
  width: 299px;
  height: 360px;
}
.aoerImg:nth-child(5) {
  width: 658px;
  height: 360px;
}
.aoerImg:nth-child(6) {
  width: 286px;
  height: 187px;
}
.aoerImg:nth-child(7) {
  width: 328px;
  height: 187px;
}
.aoerImg:nth-child(8) {
  width: 328px;
  height: 187px;
}
@media (min-width: 1366px) and (max-width: 1440px) {
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 563px;
  }
  div /deep/ .el-carousel__container {
    height: 563px !important;
  }
}
@media screen and (max-width: 1366px) {
  div /deep/ .el-carousel__container {
    height: 534px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 534px;
  }
  .main {
    width: 910px;
    margin: 30px auto;
  }
  .mainTitle {
    width: 910px;
    height: 100px;
    margin: 0 auto;
    position: relative;
  }
  .mainTitle img {
    width: 100%;
    height: 100%;
  }
  .mainTitle span {
    font-size: 43px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    position: absolute;
    left: 50%;
    top: 32px;
    transform: translateX(-50%);
  }
  .allContent {
    width: 100%;
    margin: 90px auto;
    display: flex;
    justify-content: space-between;
  }
  .leftTitle {
    width: 171px;
  }
  .leftItem {
    width: 100%;
    height: 63px;
    background: #ffe500;
    border-radius: 100px 100px 100px 100px;
    opacity: 1;
    font-size: 17px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    line-height: 63px;
    text-align: center;
    margin-bottom: 43px;
    cursor: pointer;
  }
  .leftItemActive {
    background: #25ae85;
    font-weight: 500;
    color: #ffffff;
  }
  .rightContent {
    width: 695px;
  }

  .rightAll {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    cursor: pointer;
  }
  .biyeImg,
  .jiaoyouImg,
  .ertongImg,
  .xijuImg,
  .yishuImg,
  .xiaofangImg,
  .dashuiImg,
  .aoerImg {
    margin-bottom: 20px;
  }
  .biyeImg:nth-child(1) {
    width: 271px;
    height: 271px;
  }
  .biyeImg:nth-child(2) {
    width: 407px;
    height: 271px;
  }
  .biyeImg:nth-child(3) {
    width: 406px;
    height: 182px;
  }
  .biyeImg:nth-child(4) {
    width: 273px;
    height: 182px;
  }
  .biyeImg:nth-child(5) {
    width: 341px;
    height: 226px;
  }
  .biyeImg:nth-child(6) {
    width: 341px;
    height: 226px;
  }
  .biyeImg:nth-child(7) {
    width: 220px;
    height: 149px;
  }
  .biyeImg:nth-child(8) {
    width: 223px;
    height: 149px;
  }
  .biyeImg:nth-child(9) {
    width: 223px;
    height: 149px;
  }
  .jiaoyouImg:nth-child(1) {
    width: 340px;
    height: 255px;
  }
  .jiaoyouImg:nth-child(2) {
    width: 340px;
    height: 255px;
  }
  .jiaoyouImg:nth-child(3) {
    width: 268px;
    height: 310px;
  }
  .jiaoyouImg:nth-child(4) {
    width: 413px;
    height: 310px;
  }
  .jiaoyouImg:nth-child(5) {
    width: 695px;
    height: 240px;
  }
  .jiaoyouImg:nth-child(6) {
    width: 223px;
    height: 167px;
  }
  .jiaoyouImg:nth-child(7) {
    width: 223px;
    height: 167px;
  }
  .jiaoyouImg:nth-child(8) {
    width: 223px;
    height: 167px;
  }
  .jiaoyouImg:nth-child(9) {
    width: 245px;
    height: 184px;
  }
  .jiaoyouImg:nth-child(10) {
    width: 183px;
    height: 184px;
  }
  .jiaoyouImg:nth-child(11) {
    width: 245px;
    height: 184px;
  }

  .ertongImg:nth-child(1) {
    width: 245px;
    height: 326px;
  }
  .ertongImg:nth-child(2) {
    width: 435px;
    height: 326px;
  }
  .ertongImg:nth-child(3) {
    width: 340px;
    height: 255px;
  }
  .ertongImg:nth-child(4) {
    width: 340px;
    height: 255px;
  }
  .ertongImg:nth-child(5) {
    width: 695px;
    height: 521px;
  }
  .xijuImg:nth-child(1) {
    width: 473px;
    height: 299px;
  }
  .xijuImg:nth-child(2) {
    width: 205px;
    height: 299px;
  }
  .xijuImg:nth-child(3) {
    width: 695px;
    height: 322px;
  }
  .xijuImg:nth-child(4) {
    width: 327px;
    height: 386px;
  }
  .xijuImg:nth-child(5) {
    width: 354px;
    height: 386px;
  }
  .xijuImg:nth-child(6) {
    width: 695px;
    height: 313px;
  }

  .yishuImg:nth-child(1) {
    width: 436px;
    height: 327px;
  }
  .yishuImg:nth-child(2) {
    width: 245px;
    height: 327px;
  }
  .yishuImg:nth-child(3) {
    width: 245px;
    height: 326px;
  }
  .yishuImg:nth-child(4) {
    width: 435px;
    height: 326px;
  }
  .yishuImg:nth-child(5) {
    width: 435px;
    height: 327px;
  }
  .yishuImg:nth-child(6) {
    width: 245px;
    height: 327px;
  }
  .yishuImg:nth-child(7) {
    width: 695px;
    height: 277px;
  }
  .xiaofangImg:nth-child(1) {
    width: 262px;
    height: 196px;
  }
  .xiaofangImg:nth-child(2) {
    width: 262px;
    height: 196px;
  }
  .xiaofangImg:nth-child(3) {
    width: 148px;
    height: 196px;
  }
  .xiaofangImg:nth-child(4) {
    width: 213px;
    height: 174px;
  }
  .xiaofangImg:nth-child(5) {
    width: 221px;
    height: 174px;
  }
  .xiaofangImg:nth-child(6) {
    width: 233px;
    height: 174px;
  }
  .xiaofangImg:nth-child(7) {
    width: 341px;
    height: 255px;
  }
  .xiaofangImg:nth-child(8) {
    width: 341px;
    height: 255px;
  }
  .xiaofangImg:nth-child(9) {
    width: 210px;
    height: 171px;
  }
  .xiaofangImg:nth-child(10) {
    width: 228px;
    height: 171px;
  }
  .xiaofangImg:nth-child(11) {
    width: 228px;
    height: 171px;
  }

  .dashuiImg:nth-child(1) {
    width: 322px;
    height: 269px;
  }
  .dashuiImg:nth-child(2) {
    width: 358px;
    height: 269px;
  }
  .dashuiImg:nth-child(3) {
    width: 201px;
    height: 254px;
  }
  .dashuiImg:nth-child(4) {
    width: 304px;
    height: 254px;
  }
  .dashuiImg:nth-child(5) {
    width: 162px;
    height: 254px;
  }
  .dashuiImg:nth-child(6) {
    width: 255px;
    height: 191px;
  }
  .dashuiImg:nth-child(7) {
    width: 204px;
    height: 191px;
  }
  .dashuiImg:nth-child(8) {
    width: 203px;
    height: 191px;
  }
  .dashuiImg:nth-child(9) {
    width: 341px;
    height: 255px;
  }
  .dashuiImg:nth-child(10) {
    width: 341px;
    height: 255px;
  }
  .aoerImg:nth-child(1) {
    width: 695px;
    height: 248px;
  }
  .aoerImg:nth-child(2) {
    width: 354px;
    height: 199px;
  }
  .aoerImg:nth-child(3) {
    width: 327px;
    height: 199px;
  }
  .aoerImg:nth-child(4) {
    width: 213px;
    height: 256px;
  }
  .aoerImg:nth-child(5) {
    width: 468px;
    height: 256px;
  }
  .aoerImg:nth-child(6) {
    width: 203px;
    height: 133px;
  }
  .aoerImg:nth-child(7) {
    width: 233px;
    height: 133px;
  }
  .aoerImg:nth-child(8) {
    width: 233px;
    height: 133px;
  }
}
@media screen and (max-width: 1280px) {
  div /deep/ .el-carousel__container {
    height: 500px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 500px;
  }
  .main {
    width: 850px;
    margin: 30px auto;
  }
  .mainTitle {
    width: 800px;
    height: 100px;
    margin: 0 auto;
    position: relative;
  }
  .mainTitle img {
    width: 100%;
    height: 100%;
  }
  .mainTitle span {
    font-size: 40px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    position: absolute;
    left: 50%;
    top: 32px;
    transform: translateX(-50%);
  }
  .allContent {
    width: 100%;
    margin: 90px auto;
    display: flex;
    justify-content: space-between;
  }
  .leftTitle {
    width: 160px;
  }
  .leftItem {
    width: 100%;
    height: 60px;
    background: #ffe500;
    border-radius: 100px 100px 100px 100px;
    opacity: 1;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    line-height: 60px;
    text-align: center;
    margin-bottom: 43px;
    cursor: pointer;
  }
  .leftItemActive {
    background: #25ae85;
    font-weight: 500;
    color: #ffffff;
  }
  .rightContent {
    width: 651px;
  }

  .rightAll {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    cursor: pointer;
  }
  .biyeImg,
  .jiaoyouImg,
  .ertongImg,
  .xijuImg,
  .yishuImg,
  .xiaofangImg,
  .dashuiImg,
  .aoerImg {
    margin-bottom: 20px;
  }
  .biyeImg:nth-child(1) {
    width: 254px;
    height: 254px;
  }
  .biyeImg:nth-child(2) {
    width: 381px;
    height: 254px;
  }
  .biyeImg:nth-child(3) {
    width: 380px;
    height: 171px;
  }
  .biyeImg:nth-child(4) {
    width: 256px;
    height: 171px;
  }
  .biyeImg:nth-child(5) {
    width: 319px;
    height: 212px;
  }
  .biyeImg:nth-child(6) {
    width: 319px;
    height: 212px;
  }
  .biyeImg:nth-child(7) {
    width: 206px;
    height: 139px;
  }
  .biyeImg:nth-child(8) {
    width: 209px;
    height: 139px;
  }
  .biyeImg:nth-child(9) {
    width: 209px;
    height: 139px;
  }
  .jiaoyouImg:nth-child(1) {
    width: 319px;
    height: 239px;
  }
  .jiaoyouImg:nth-child(2) {
    width: 319px;
    height: 239px;
  }
  .jiaoyouImg:nth-child(3) {
    width: 251px;
    height: 291px;
  }
  .jiaoyouImg:nth-child(4) {
    width: 387px;
    height: 291px;
  }
  .jiaoyouImg:nth-child(5) {
    width: 651px;
    height: 225px;
  }
  .jiaoyouImg:nth-child(6) {
    width: 209px;
    height: 157px;
  }
  .jiaoyouImg:nth-child(7) {
    width: 209px;
    height: 157px;
  }
  .jiaoyouImg:nth-child(8) {
    width: 209px;
    height: 157px;
  }
  .jiaoyouImg:nth-child(9) {
    width: 230px;
    height: 173px;
  }
  .jiaoyouImg:nth-child(10) {
    width: 171px;
    height: 173px;
  }
  .jiaoyouImg:nth-child(11) {
    width: 230px;
    height: 173px;
  }

  .ertongImg:nth-child(1) {
    width: 229px;
    height: 305px;
  }
  .ertongImg:nth-child(2) {
    width: 407px;
    height: 305px;
  }
  .ertongImg:nth-child(3) {
    width: 319px;
    height: 239px;
  }
  .ertongImg:nth-child(4) {
    width: 319px;
    height: 239px;
  }
  .ertongImg:nth-child(5) {
    width: 651px;
    height: 488px;
  }
  .xijuImg:nth-child(1) {
    width: 443px;
    height: 280px;
  }
  .xijuImg:nth-child(2) {
    width: 192px;
    height: 280px;
  }
  .xijuImg:nth-child(3) {
    width: 651px;
    height: 301px;
  }
  .xijuImg:nth-child(4) {
    width: 307px;
    height: 362px;
  }
  .xijuImg:nth-child(5) {
    width: 331px;
    height: 362px;
  }
  .xijuImg:nth-child(6) {
    width: 651px;
    height: 293px;
  }

  .yishuImg:nth-child(1) {
    width: 409px;
    height: 306px;
  }
  .yishuImg:nth-child(2) {
    width: 229px;
    height: 306px;
  }
  .yishuImg:nth-child(3) {
    width: 229px;
    height: 305px;
  }
  .yishuImg:nth-child(4) {
    width: 407px;
    height: 305px;
  }
  .yishuImg:nth-child(5) {
    width: 408px;
    height: 307px;
  }
  .yishuImg:nth-child(6) {
    width: 230px;
    height: 307px;
  }
  .yishuImg:nth-child(7) {
    width: 651px;
    height: 260px;
  }

  .xiaofangImg:nth-child(1) {
    width: 245px;
    height: 184px;
  }
  .xiaofangImg:nth-child(2) {
    width: 245px;
    height: 184px;
  }
  .xiaofangImg:nth-child(3) {
    width: 139px;
    height: 184px;
  }
  .xiaofangImg:nth-child(4) {
    width: 199px;
    height: 163px;
  }
  .xiaofangImg:nth-child(5) {
    width: 207px;
    height: 163px;
  }
  .xiaofangImg:nth-child(6) {
    width: 218px;
    height: 163px;
  }
  .xiaofangImg:nth-child(7) {
    width: 319px;
    height: 239px;
  }
  .xiaofangImg:nth-child(8) {
    width: 319px;
    height: 239px;
  }
  .xiaofangImg:nth-child(9) {
    width: 197px;
    height: 160px;
  }
  .xiaofangImg:nth-child(10) {
    width: 214px;
    height: 160px;
  }
  .xiaofangImg:nth-child(11) {
    width: 214px;
    height: 160px;
  }

  .dashuiImg:nth-child(1) {
    width: 302px;
    height: 252px;
  }
  .dashuiImg:nth-child(2) {
    width: 336px;
    height: 252px;
  }
  .dashuiImg:nth-child(3) {
    width: 188px;
    height: 238px;
  }
  .dashuiImg:nth-child(4) {
    width: 285px;
    height: 238px;
  }
  .dashuiImg:nth-child(5) {
    width: 152px;
    height: 238px;
  }
  .dashuiImg:nth-child(6) {
    width: 239px;
    height: 179px;
  }
  .dashuiImg:nth-child(7) {
    width: 191px;
    height: 179px;
  }
  .dashuiImg:nth-child(8) {
    width: 191px;
    height: 179px;
  }
  .dashuiImg:nth-child(9) {
    width: 319px;
    height: 239px;
  }
  .dashuiImg:nth-child(10) {
    width: 319px;
    height: 239px;
  }
  .aoerImg:nth-child(1) {
    width: 651px;
    height: 233px;
  }
  .aoerImg:nth-child(2) {
    width: 332px;
    height: 187px;
  }
  .aoerImg:nth-child(3) {
    width: 306px;
    height: 187px;
  }
  .aoerImg:nth-child(4) {
    width: 199px;
    height: 240px;
  }
  .aoerImg:nth-child(5) {
    width: 439px;
    height: 240px;
  }
  .aoerImg:nth-child(6) {
    width: 191px;
    height: 125px;
  }
  .aoerImg:nth-child(7) {
    width: 219px;
    height: 125px;
  }
  .aoerImg:nth-child(8) {
    width: 219px;
    height: 125px;
  }
}
@media screen and (max-width: 750px) {
  .huodong {
    overflow-x: hidden;
  }
  .screen1920 {
    display: none;
  }
  .screen750 {
    display: block;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 146px;
  }
  div /deep/ .el-carousel__container {
    height: 146px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 146px;
  }
  .main {
    width: 375px;
    margin: 20px auto;
  }
  .mainTitle {
    width: 375px;
    height: 50px;
    margin: 0 auto;
    position: relative;
  }
  .mainTitle img {
    width: 100%;
    height: 100%;
  }
  .mainTitle span {
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #25ae85;
    position: absolute;
    left: 50%;
    top: 12px;
    transform: translateX(-50%);
  }

  .allContent {
    width: 345px;
    margin: 20px auto;
    display: block;
  }
  .leftTitle {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  .leftItem {
    width: 88px;
    height: 28px;
    background: #ffe500;
    border-radius: 100px 100px 100px 100px;
    opacity: 1;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    margin-right: 16px;
    flex-shrink: 0;
  }
  .leftItemActive {
    background: #25ae85;
    font-weight: 500;
    color: #ffffff;
  }
  .rightContent {
    width: 100%;
  }

  .rightAll {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    cursor: pointer;
  }

  .biyeImg:nth-child(1) {
    width: 134px;
    height: 134px;
  }
  .biyeImg:nth-child(2) {
    width: 200px;
    height: 134px;
  }
  .biyeImg:nth-child(3) {
    width: 200px;
    height: 90px;
  }
  .biyeImg:nth-child(4) {
    width: 134px;
    height: 90px;
  }
  .biyeImg:nth-child(5) {
    width: 169px;
    height: 112px;
  }
  .biyeImg:nth-child(6) {
    width: 168px;
    height: 112px;
  }
  .biyeImg:nth-child(7) {
    width: 108px;
    height: 73px;
  }
  .biyeImg:nth-child(8) {
    width: 110px;
    height: 73px;
  }
  .biyeImg:nth-child(9) {
    width: 110px;
    height: 73px;
  }
  .jiaoyouImg:nth-child(1) {
    width: 168px;
    height: 126px;
  }
  .jiaoyouImg:nth-child(2) {
    width: 168px;
    height: 126px;
  }
  .jiaoyouImg:nth-child(3) {
    width: 132px;
    height: 153px;
  }
  .jiaoyouImg:nth-child(4) {
    width: 204px;
    height: 153px;
  }
  .jiaoyouImg:nth-child(5) {
    width: 343px;
    height: 119px;
  }
  .jiaoyouImg:nth-child(6) {
    width: 110px;
    height: 82px;
  }
  .jiaoyouImg:nth-child(7) {
    width: 110px;
    height: 82px;
  }
  .jiaoyouImg:nth-child(8) {
    width: 110px;
    height: 82px;
  }
  .jiaoyouImg:nth-child(9) {
    width: 121px;
    height: 91px;
  }
  .jiaoyouImg:nth-child(10) {
    width: 90px;
    height: 91px;
  }
  .jiaoyouImg:nth-child(11) {
    width: 121px;
    height: 91px;
  }

  .ertongImg:nth-child(1) {
    width: 121px;
    height: 161px;
  }
  .ertongImg:nth-child(2) {
    width: 215px;
    height: 161px;
  }
  .ertongImg:nth-child(3) {
    width: 168px;
    height: 126px;
  }
  .ertongImg:nth-child(4) {
    width: 168px;
    height: 126px;
  }
  .ertongImg:nth-child(5) {
    width: 343px;
    height: 257px;
  }
  .xijuImg:nth-child(1) {
    width: 233px;
    height: 147px;
  }
  .xijuImg:nth-child(2) {
    width: 101px;
    height: 147px;
  }
  .xijuImg:nth-child(3) {
    width: 343px;
    height: 159px;
  }
  .xijuImg:nth-child(4) {
    width: 161px;
    height: 190px;
  }
  .xijuImg:nth-child(5) {
    width: 174px;
    height: 190px;
  }
  .xijuImg:nth-child(6) {
    width: 343px;
    height: 154px;
  }

  .yishuImg:nth-child(1) {
    width: 215px;
    height: 161px;
  }
  .yishuImg:nth-child(2) {
    width: 121px;
    height: 161px;
  }
  .yishuImg:nth-child(3) {
    width: 121px;
    height: 161px;
  }
  .yishuImg:nth-child(4) {
    width: 215px;
    height: 161px;
  }
  .yishuImg:nth-child(5) {
    width: 215px;
    height: 161px;
  }
  .yishuImg:nth-child(6) {
    width: 121px;
    height: 161px;
  }
  .yishuImg:nth-child(7) {
    width: 343px;
    height: 137px;
  }
  .xiaofangImg:nth-child(1) {
    width: 129px;
    height: 97px;
  }
  .xiaofangImg:nth-child(2) {
    width: 129px;
    height: 97px;
  }
  .xiaofangImg:nth-child(3) {
    width: 73px;
    height: 97px;
  }
  .xiaofangImg:nth-child(4) {
    width: 105px;
    height: 86px;
  }
  .xiaofangImg:nth-child(5) {
    width: 109px;
    height: 86px;
  }
  .xiaofangImg:nth-child(6) {
    width: 115px;
    height: 86px;
  }
  .xiaofangImg:nth-child(7) {
    width: 168px;
    height: 126px;
  }
  .xiaofangImg:nth-child(8) {
    width: 168px;
    height: 126px;
  }
  .xiaofangImg:nth-child(9) {
    width: 104px;
    height: 84px;
  }
  .xiaofangImg:nth-child(10) {
    width: 113px;
    height: 84px;
  }
  .xiaofangImg:nth-child(11) {
    width: 113px;
    height: 84px;
  }

  .dashuiImg:nth-child(1) {
    width: 159px;
    height: 133px;
  }
  .dashuiImg:nth-child(2) {
    width: 177px;
    height: 133px;
  }
  .dashuiImg:nth-child(3) {
    width: 99px;
    height: 125px;
  }
  .dashuiImg:nth-child(4) {
    width: 150px;
    height: 125px;
  }
  .dashuiImg:nth-child(5) {
    width: 80px;
    height: 125px;
  }
  .dashuiImg:nth-child(6) {
    width: 126px;
    height: 94px;
  }
  .dashuiImg:nth-child(7) {
    width: 101px;
    height: 94px;
  }
  .dashuiImg:nth-child(8) {
    width: 100px;
    height: 94px;
  }
  .dashuiImg:nth-child(9) {
    width: 168px;
    height: 126px;
  }
  .dashuiImg:nth-child(10) {
    width: 168px;
    height: 126px;
  }
  .aoerImg:nth-child(1) {
    width: 343px;
    height: 123px;
  }
  .aoerImg:nth-child(2) {
    width: 175px;
    height: 98px;
  }
  .aoerImg:nth-child(3) {
    width: 161px;
    height: 98px;
  }
  .aoerImg:nth-child(4) {
    width: 105px;
    height: 126px;
  }
  .aoerImg:nth-child(5) {
    width: 231px;
    height: 126px;
  }
  .aoerImg:nth-child(6) {
    width: 100px;
    height: 66px;
  }
  .aoerImg:nth-child(7) {
    width: 115px;
    height: 66px;
  }
  .aoerImg:nth-child(8) {
    width: 115px;
    height: 66px;
  }
}
</style>
